import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth/useAuth'
import { loadYepCodeSDK } from 'utils/yepcode'

const ToGenerateInvoiceModal = ({ booking, isOpen, close }) => {
  const [sdkLoaded, setSdkLoaded] = useState(false)
  const {
    keycloak: { token: accessToken }
  } = useAuth()

  useEffect(() => {
    if (isOpen && !sdkLoaded) {
      loadYepCodeSDK()
        .then(() => {
          setSdkLoaded(true)
        })
        .catch((error) => {
          console.error('Failed to load YepCode SDK:', error)
        })
    }
  }, [isOpen, sdkLoaded])

  useEffect(() => {
    if (isOpen && sdkLoaded && window.YepCode) {
      window.YepCode.initForm('#electronic-invoice-form', {
        team: 'balaena',
        process: '00f44804-964a-446c-86a4-e42442d62e69',
        options: {
          theme: 'light'
        },
        defaultValues: {
          accessToken,
          booking: {
            id: booking.id,
            dateTime: booking.dateTime,
            bookable: {
              id: booking.bookable.id,
              name: booking.bookable.name
            },
            mainGuest: booking.guests[0],
            amount: (booking.payment.amount / 100).toFixed(2),
            taxesDetails: {
              baseAmount: (
                booking.payment.taxesDetails.totalAmount / 100
              ).toFixed(2),
              taxes: booking.payment.taxesDetails.taxes.map((tax) => ({
                percentage: tax.percentage.toFixed(2),
                amount: (tax.amount / 100).toFixed(2),
                name: tax.name
              }))
            }
          }
        }
      })
    }
  }, [isOpen, sdkLoaded, booking, accessToken])

  return (
    <Modal
      name="ToGenerateInvoiceModal"
      className="ToGenerateInvoiceModal"
      isOpen={isOpen}
      onClose={close}
      body={<div id="electronic-invoice-form"></div>}
    />
  )
}

ToGenerateInvoiceModal.propTypes = {
  booking: PropTypes.shape(),
  handleGenerateInvoice: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

ToGenerateInvoiceModal.displayName = 'ToGenerateInvoiceModal'

export default ToGenerateInvoiceModal
