import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Duration from 'components/misc/Duration'
import BookingDate from 'components/booker/BookingForm/BookingDate'
import moment from 'moment'
import _ from 'lodash'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'

const BookingDateBlock = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'bookingDateBlock'
  })
  const { classNames } = useClassNames({
    alias: 'BookingDateBlock'
  })

  const BookingDateInformation = ({ booking }) => {
    const duration = useMemo(() => {
      let duration = booking.bookable.duration
      if (booking.bookable.continuous && !_.isEmpty(booking.dateRange)) {
        const from = moment(booking.dateRange.from)
        const to = moment(booking.dateRange.to)

        duration = moment.duration(to.diff(from)).asMinutes()
      }
      return duration
    }, [booking])

    return (
      <div>
        <p className={classNames('bookingDate')}>
          <BookingDate
            dateTime={booking.dateTime}
            dateRange={booking.dateRange}
            timeZone={booking.bookable.timeZone}
          />
        </p>
        <p className={classNames('bookingDuration')}>
          {msg(
            { id: 'duration' },
            {
              duration: <Duration amount={duration} />
            }
          )}
        </p>
        {_.isEmpty(booking.children) ? null : (
          <div className={classNames('children')}>
            <h3>{msg({ id: 'includedInBundle' })}</h3>
            <ul>
              {booking.children
                .sort((a, b) => a.dateTime.localeCompare(b.dateTime))
                .map((child) => (
                  <li key={child.id}>
                    <p className={classNames('childBookableName')}>
                      {child.bookable.name}
                    </p>
                    <BookingDateInformation booking={child} />
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
  BookingDateInformation.propTypes = {
    booking: PropTypes.shape()
  }

  return (
    <BookingDetailsSummaryBlock
      className="BookingDateBlock"
      title={msg({ id: 'title' })}
    >
      <BookingDateInformation booking={booking} />
    </BookingDetailsSummaryBlock>
  )
}

BookingDateBlock.propTypes = {
  booking: PropTypes.shape(),
  bookable: PropTypes.shape()
}

BookingDateBlock.displayName = 'BookingDateBlock'

export default BookingDateBlock
