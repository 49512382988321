export const loadYepCodeSDK = () => {
  return new Promise((resolve, reject) => {
    if (window.YepCode) {
      resolve(window.YepCode)
      return
    }

    const script = document.createElement('script')
    script.src = 'https://yepcode.io/sdk/forms.js'
    script.defer = true
    script.async = true
    script.onload = () => resolve(window.YepCode)
    script.onerror = reject
    document.head.appendChild(script)
  })
}
